<template>
    <footer class="bg-off-white footer">
        <div class="footer-shapes">
            <div class="footer-shape">
                <img src="../../assets/images/shape-1.png" alt="shape">
            </div>
            <div class="footer-shape footer-round-shape">
                <img src="../../assets/images/shape-2.png" alt="shape">
            </div>
        </div>
        <div class="footer-upper pt-100 pb-80 position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="footer-content-item">
                            <div class="footer-logo">
                                <router-link to="/">
                                    <img src="../../assets/images/logo.png" alt="logo">
                                </router-link>
                            </div>
                            <div class="footer-details">
                                <p>Xmatiq Technologies, where innovation meets excellence. We are a trusted IT tech company, providing tailored solutions to businesses of all sizes. With our expertise in software development and network infrastructure, we empower your business to thrive in the digital era.</p>
                                <p>Copyright ©{{currentYear}} Techforever Solutions LLP.</p>
                                <!-- <ul class="social-list social-list-btn">
                                    <li>
                                        <a href="https://linkedin.com/" target="_blank">
                                            <i class="icofont-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="icofont-facebook"></i>
                                        </a>
                                    </li>
                                  
                                  
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="icofont-twitter"></i>
                                        </a>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <li><router-link to="/services">Services</router-link></li>
                                <li><router-link to="/testimonial">Testimonials</router-link></li>
                                <li><router-link to="/contact">Contact</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Services</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/machine-learning">Machine Learning & Artificial Intelligence</router-link></li>
                                <li><router-link to="/computer-vision">Computer Vision</router-link></li>
                                <li><router-link to="/data-analytics">Data Analytics</router-link></li>
                                <li><router-link to="/iot">IoT</router-link></li>
                                <li><router-link to="/erp">Custom Software Development</router-link></li>
                                <li><router-link to="/erp">Enterprise Resource Planning (ERP)</router-link></li>
                                <li><router-link to="/mobile-web-development"> Mobile App Development</router-link></li>
                                <li><router-link to="/mobile-web-development"> Website Development</router-link></li>
                                <li><router-link to="/accounting-software"> Accounting Software</router-link></li>
                                <li><router-link to="/pos">  Point of Sale (POS) Solutions</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Contact</h3>
                            </div>
                            <div class="footer-details footer-address">
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Phone:</h4>
                                        <!-- <p> <img src="../../assets/images/india.png" style="height: 1.5rem;" alt="shape"> <a href="tel:091-999-801-0990">999-801-0990</a> / <a href="tel:091-999-845-5066">999-845-5066</a></p> -->
                                        <p><img src="../../assets/images/india.png" style="height: 1.5rem;" alt="shape"> <a href="tel:+919998455066">+91 99984 55066</a></p>
                                        <p><img src="../../assets/images/india.png" style="height: 1.5rem;" alt="shape"> <a href="tel:+918758076356">+91 87580 76356</a></p>
                                        <p></p>
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Email:</h4>
                                        <p>
                                    <a href="mailto:contact@xmatiq.com">contact@xmatiq.com</a>

                                        </p>
                                        <!-- <p><a href="mailto:contact@xmatiq.com">contact@xmatiq.com</a></p> -->
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Address:</h4>
                                        <p>301, Venus Benecia, Bodakdev, Ahmdebad - 380053, Gujarat, India</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="footer-lower bg-blue position-relative">
            <div class="container">
                <div class="footer-copyright-text footer-copyright-text-white">
                    <p>Copyright ©{{currentYear}} Techforever Solutions LLP.
                        Designed & Developed By <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
                    </p>
                </div>
            </div>
        </div> -->
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>